export enum REST_RESULT_TYPE {
  OK = 'OK',
  ERROR = 'ERROR',
}

export enum FormGuids {
  PersonalInfo = '64009013-c67b-4591-a0ab-07d831fed54a',
  BillingInfo = 'be16429c-b450-4f17-b94c-8e9e720ed665',
  ShippingInfo = '036637d9-a713-4ce0-9420-51a9aa4dda08',
  BusinessInfo = '4bc276b8-0d98-42da-ae99-6132b32897a7',
  Support = 'ede457b1-a53e-4688-910a-11f97145d4d0',
  CreditRequest = '294ef84e-1b2e-48c6-9c0e-c7e6c8eb566e',
  NewAccount = '3c55ff12-7f1e-485e-857f-9cc753c0e0fb',
}

export const VBN_DATE_FORMAT = 'yyyy-MM-dd';
