import { Injectable, isDevMode, OnInit } from '@angular/core';

import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { RestResult, User } from './interfaces';
import { Observable, of } from 'rxjs';
import { REST_RESULT_TYPE } from './constants';
import { KeycloakProfile } from 'keycloak-js';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable()
export class UserService implements OnInit {
  private baseURL: string;
  private loggedIn = false;
  private userProfile: KeycloakProfile | null = null;
  private user: User = {} as User;

  constructor(
    private readonly keycloak: KeycloakService,
    private http: HttpClient,
  ) {
    this.baseURL = environment.apiHost;
  }

  public async ngOnInit() {
    this.loggedIn = await this.keycloak.isLoggedIn();

    if (this.loggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.getUser();
    }
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  getUser(): Observable<RestResult> {
    console.log('isDevMode? ' + isDevMode());
    console.log('skipAuthentication? ' + environment.skipAuthentication);

    if (isDevMode() && environment.skipAuthentication) {
      if (this.user === undefined || this.user === null || Object.keys(this.user).length === 0) {
        this.user = {
          customerName: 'Mocked Customer',
          username: 'mocked',
          email: 'somebody@mocked.domain',
          firstName: 'Mocked',
          id: -1,
          lastName: 'User',
          emailVerified: true,
        };
      }

      const mockedResult: RestResult<User> = {
        result: REST_RESULT_TYPE.OK,
        value: this.user,
        errorMessage: '',
      };

      return of(mockedResult);
    } else {
      if (this.user === undefined || this.user === null || Object.keys(this.user).length === 0) {
        const url = `${this.baseURL}/user`;
        return this.http.get<RestResult>(url).pipe();
      } else {
        const cachedResult: RestResult<User> = {
          result: REST_RESULT_TYPE.OK,
          value: this.user,
          errorMessage: '',
        };

        return of(cachedResult);
      }
    }
  }

  addMessage(message: string): Observable<RestResult> {
    const url = `${this.baseURL}/message/add`;
    return this.http.post<RestResult>(url, message, httpOptions).pipe();
  }
}
