import { Component, input } from '@angular/core';
import { MatButton, MatFabButton } from '@angular/material/button';

@Component({
  selector: 'vbn-simple-banner',
  standalone: true,
  templateUrl: './simple-banner.component.html',
  styleUrls: ['./simple-banner.component.scss'],
  imports: [MatButton, MatFabButton],
})
export class SimpleBannerComponent {
  title = input('');
  subTitle = input('');
}
