import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from './user.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly userProfileService: UserService,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isDevMode() && environment.skipAuthentication) {
      this.authenticated = true;
      return true;
    }

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    } else {
      // We don't await this result because we are just priming the result
      // rather than requiring it.
      this.userProfileService.getUser();
    }

    // Get the roles required from the route.
    //const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    // if (!Array.isArray(requiredRoles) || requiredRoles.length === 0)
    // {
    //     return true;
    // }

    // Allow the user to proceed if all the required roles are present.
    //return requiredRoles.every((role) => this.roles.includes(role));

    return this.authenticated;
  }
}
